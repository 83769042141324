body {
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  background-color: #f8f9fa;
  color: #333333;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: #2d5f8b;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

/* Links */
a {
  color: #107896;
  text-decoration: none;
  transition: color 0.3s ease, box-shadow 0.3s ease;
}

a:hover,
a.active {
  color: rgba(108, 207, 246, 0.25);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* General Layout Enhancements */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

p,
li,
span {
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  letter-spacing: 0.02em;
  margin-bottom: 1rem;
}

button {
  font-family: 'Montserrat', 'Arial', sans-serif;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #2d5f8b;
  color: #ffffff;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover {
  background-color: rgba(16, 120, 150, 0.25);
  color: #ffffff;
  transform: scale(1.02);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .container {
    max-width: 960px;
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px;
    padding: 0.5rem;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  p {
    font-size: 0.9rem;
  }

  button {
    padding: 0.5rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 100%;
    padding: 0.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  p {
    font-size: 0.85rem;
  }

  button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}