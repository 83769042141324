.footer {
  box-sizing: border-box;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  color: #333333;
  text-align: center;
  position: relative;
  width: 100%;
  border-top: none;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}

.footer-icons {
  display: flex;
  gap: 1rem;
}

.footer-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 50%;
  transition: transform 0.2s, box-shadow 0.3s, background-color 0.3s;
}

.footer-icons a:hover {
  transform: scale(1.03);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #e6eaef;
}

.footer p {
  margin: 0;
  color: #000000;
}

.footer a {
  color: #107896;
  text-decoration: none;
  transition: color 0.3s ease;
  margin: 0 0.5rem;
}

.footer a:hover,
.footer a.active {
  color: rgba(108, 207, 246, 0.25);
}

.footer h3,
.footer h4 {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 600;
  letter-spacing: 0.03em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    padding: 1rem;
  }

  .footer-icons {
    justify-content: center;
    gap: 1.5rem;
  }

  .footer-icons a {
    width: 35px;
    height: 35px;
  }

  .footer p {
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .footer-icons {
    gap: 1rem;
  }

  .footer-icons a {
    width: 30px;
    height: 30px;
  }

  .footer p {
    font-size: 0.9rem;
  }
}