.contact {
  padding: 2rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 40px auto;
  font-family: 'Merriweather', serif;
  transition: transform 0.2s, box-shadow 0.3s;
}

.contact:hover {
  transform: scale(1.01);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.contact h2 {
  color: #2d5f8b;
  margin-bottom: 20px;
  font-family: 'Merriweather', serif;
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  display: inline-block;
}

.contact h2:hover {
  color: rgba(16, 120, 150, 0.25);
}

.contact h2::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: -10%;
  width: 120%;
  height: 2px;
  background-color: #6ccff6;
  transition:
    width 0.3s ease,
    left 0.3s ease;
}

.contact h2:hover::after {
  left: -15%;
  width: 130%;
}

.contact p {
  font-size: 1rem;
  color: #2c2c2c;
  margin-top: 1rem;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

.contact p:hover {
  transform: scale(1.01);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact {
    padding: 1.5rem;
    margin: 30px auto;
  }

  .contact h2 {
    font-size: 2rem;
  }

  .contact p {
    font-size: 0.95rem;
    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .contact {
    padding: 1rem;
    margin: 20px auto;
  }

  .contact h2 {
    font-size: 1.8rem;
  }

  .contact p {
    font-size: 0.9rem;
    line-height: 1.4;
  }
}

.contact-form label {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.contact-form input,
.contact-form textarea,
.contact-form button {
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
}