.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffffff;
  color: #333333;
  border-bottom: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
}

.header h1 {
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-weight: 700;
  letter-spacing: 0.04em;
  font-size: 2.5rem;
  color: #2d5f8b;
  transition: color 0.3s ease;
  margin: 0;
}

.header h1:hover {
  color: rgba(45, 95, 139, 0.25);
}

nav {
  margin-left: auto;
  padding-right: 2rem;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* Ensure the navbar stays horizontal by wrapping items */
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

nav ul li {
  position: relative;
}

nav ul li a {
  color: #107896;
  text-decoration: none;
  font-size: 1.2rem;
  transition:
    color 0.3s ease,
    transform 0.3s;
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
}

nav ul li a:hover {
  color: rgba(108, 207, 246, 0.25);
  transform: scale(1.02);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

nav ul li a.active {
  color: #6ccff6;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

nav ul li a::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #6ccff6;
  transition:
    width 0.3s ease,
    left 0.3s ease;
}

nav ul li a:hover::before,
nav ul li a.active::before {
  width: 100%;
  left: 0;
}

body,
.main-content {
  padding-top: 70px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .header h1 {
    font-size: 2rem;
  }

  nav ul li a {
    font-size: 1.1rem;
  }

  nav {
    padding-right: 1rem;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 1rem;
  }

  .header h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  nav {
    padding-right: 0;
  }

  nav ul {
    justify-content: center;
    flex-direction: row;
    /* Ensure navbar stays horizontal */
    flex-wrap: wrap;
    gap: 1rem;
  }

  nav ul li a {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.5rem;
  }

  nav ul {
    justify-content: center;
    flex-direction: row;
    /* Keep horizontal on smaller screens */
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  nav ul li a {
    font-size: 1rem;
  }

  nav {
    margin-left: 0;
  }
}

/* Styles for the logo button to match navigation links */
.logo-button {
  background: none;
  border: none;
  font-size: 2rem;
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  color: #2d5f8b;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  padding: 0;
}

.logo-button:hover {
  color: rgba(16, 120, 150, 0.25);
  transform: scale(1.02);
}

.logo-button:focus {
  outline: none;
}

.logo-text {
  font-weight: bold;
  font-size: 2em;
}

.logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.header-icons {
  display: flex;
  gap: 16px;
}

.header-icons a {
  color: inherit;
  font-size: 1.2em;
}

.header-icons a:hover {
  opacity: 0.7;
}