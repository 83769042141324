.testimonials-slider {
  margin: 40px auto;
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  font-family: 'Merriweather', serif;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.testimonials-slider:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.testimonials-slider h2 {
  color: #2c2c2c;
  margin-bottom: 20px;
  font-family: 'Merriweather', serif;
  font-size: 2.2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  display: inline-block;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.testimonials-slider h2::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: -10%;
  width: 120%;
  height: 2px;
  background-color: #8c8cff;
  transition:
    width 0.5s ease,
    left 0.5s ease;
}

.testimonials-slider h2:hover::after {
  width: 120%;
  left: -10%;
}

.testimonials-slider h2:hover {
  color: #8c8cff;
  transition: color 0.3s ease;
}

.testimonial {
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.testimonial:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.testimonial p {
  font-style: italic;
  color: #2c2c2c;
  font-family: 'Merriweather', serif;
  letter-spacing: 0.02em;
  transform: scale(0.85);
  /* Scale down the quote size by 15% */
}

.testimonial h4 {
  margin-top: 10px;
  color: #d4af37;
  font-family: 'Merriweather', serif;
  font-size: 1.4rem;
}

.testimonial h5 {
  margin-top: 5px;
  color: #2c2c2c;
  font-weight: normal;
  font-family: 'Merriweather', serif;
  font-size: 1.2rem;
}

/* Ensure slider visibility */
.slick-slide {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.slick-track {
  display: flex !important;
  padding: 0 10px;
  box-sizing: border-box;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .testimonials-slider {
    padding: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .testimonials-slider h2 {
    font-size: 1.8rem;
  }

  .testimonial {
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .testimonial h4 {
    font-size: 1.3rem;
  }

  .testimonial h5 {
    font-size: 1.1rem;
  }

  .testimonial p {
    transform: scale(0.75);
    /* Further reduce the quote size by an additional 10% on smaller screens */
  }
}

@media (max-width: 480px) {
  .testimonials-slider {
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .testimonials-slider h2 {
    font-size: 1.6rem;
  }

  .testimonial {
    padding: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .testimonial h4 {
    font-size: 1.2rem;
  }

  .testimonial h5 {
    font-size: 1rem;
  }

  .testimonial p {
    transform: scale(0.7);
    /* Further reduce the quote size by an additional 15% on the smallest screens */
  }
}
